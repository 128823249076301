<template>
  <div>
    <el-drawer title="Search Filter" :visible.sync="drawerFilterVisible" direction="btt" size="50%">
      <div class="drawer">
        <el-form :ref="settings" label-width="150px" label-position="left">
          <el-form-item label="Application Status">
            <el-select v-model="settings.a_id" placeholder="Select Application Status">
              <el-option v-for="(stat, index) in statuses" :key="index" :value="stat.value" :label="stat.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="HR Status">
            <el-select v-model="settings.received_a_id" placeholder="Select HR Status">
              <el-option v-for="(stat, index) in statuses" :key="index" :value="stat.value" :label="stat.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="Approvers" :visible.sync="dialogTableVisible">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="50"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME">
          <template slot-scope="props">
            <div v-if="props.row.boss_em_id === $store.getters.user.employeeId"><b>{{props.row.boss_em_id_name}} (Me)</b></div>
            <div v-else>{{props.row.boss_em_id_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
          <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ formatDate(scope.row.a_date_action) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">Holiday Dashboard</h3>
        <el-button type="primary" icon="el-icon-user" @click="drawerFilterVisible = true">Search Filter</el-button>
      </div>
      <el-collapse accordion class="hidden-md-and-up">
        <el-collapse-item v-for="(file, index) in data" :key="index" :name="file.id">
          <template slot="title">
            <p style="width: 100%">{{ file.bg4_id_holidaytype_name }} - {{ formatDate(file.holiday_date) }} - <el-tag :type="getStatusType(file.a_id_name)">{{ file.a_id_name }}</el-tag> - <el-tag :type="getStatusType(file.received_a_id_name)">{{ file.received_a_id_name }}</el-tag></p>
          </template>
          <p>Requested By: <b>{{file.em_id_name}}</b></p>
          <p>Holiday Name: <b>{{file.name}}</b></p>
          <p>Location: <b>{{file.bbl_id_name ? file.bbl_id_name : 'Nationwide'}}</b></p>
        </el-collapse-item>
      </el-collapse>
      <el-table :data="data" border class="hidden-sm-and-down">
        <el-table-column label="REQUESTED BY" prop="em_id_name"></el-table-column>
        <el-table-column label="HOLIDAY TYPE" prop="bg4_id_holidaytype_name"></el-table-column>
        <el-table-column label="DATE" prop="holiday_date"></el-table-column>
        <el-table-column label="NAME" prop="name"></el-table-column>
        <el-table-column label="LOCATION" prop="bbl_id_name">
          <template slot-scope="props">{{props.row.bbl_id_name ? props.row.bbl_id_name : 'Nationwide'}}</template>
        </el-table-column>
        <el-table-column label="STATUS" prop="a_id_name" min-width="70">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="HR STATUS" prop="received_a_id_name" min-width="70">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.received_a_id_name)">{{ props.row.received_a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" width="170">
          <template slot-scope="scope">
            <div class="fixed-row">
              <!-- <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
                <el-button el-button size="mini" type="primary" @click="showApprovalStatus(scope.row.approvers)"><i class="el-icon-document-checked"></i></el-button>
              </el-tooltip> -->
              <div v-if="scope.row.a_id === '2'">
                <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
                  <el-button size="mini" type="success" @click="confirmApprove(scope.row, 'Holiday Approved')"><i class="el-icon-check"></i></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
                  <el-button size="mini" type="danger" @click="confirmDisapprove(scope.row, 'Holiday Disapproved')"><i class="el-icon-close"></i></el-button>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="controls">
        <div class="control">
          <p>Items per page: </p>
          <el-select v-model="items">
            <el-option :label="5" :value="5"></el-option>
            <el-option :label="10" :value="10"></el-option>
            <el-option :label="25" :value="25"></el-option>
            <el-option :label="50" :value="50"></el-option>
            <el-option :label="100" :value="100"></el-option>
          </el-select>
        </div>
        <div class="control">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
              <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
            </el-button-group>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'HolidayRequestList',
  data () {
    return {
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      data: [],
      approvers: [],
      status: null,
      drawerFilterVisible: false,
      dialogTableVisible: false,
      settings: {
        a_id: null,
        received_a_id: null
      },
      statuses: [
        {
          value: 0,
          name: 'All'
        },
        {
          value: 1,
          name: 'Waiting'
        },
        {
          value: 2,
          name: 'Approved'
        },
        {
          value: 3,
          name: 'Disapproved'
        }
      ]
    }
  },
  mounted () {
    this.getRequestList()
  },
  watch: {
    status () {
      this.getRequestList()
    },
    items () {
      this.getRequestList()
    }
  },
  methods: {
    getRequestList () {
      this.$http
        .get(`hr.holiday-list/${this.settings.a_id}/${this.settings.received_a_id}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getRequestList()
    },
    prevPage () {
      this.page--
      this.getRequestList()
    },
    closeFilterDialog () {
      this.drawerFilterVisible = false
      this.settings.a_id = null
      this.settigns.received_a_id = null
    },
    confirmFilterDialog () {
      this.drawerFilterVisible = false
      this.getRequestList()
    },
    confirmApprove (file, snackbarResponse) {
      this.$confirm('Are you sure you want to approve this holiday request?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'info'
      })
        .then(() => {
          this.update(file, snackbarResponse)
        })
    },
    confirmDisapprove (file, snackbarResponse) {
      this.$confirm('Are you sure you want to disapprove this holiday request?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          this.update(file, snackbarResponse)
        })
    },
    update (file, snackbarResponse) {
      const form = {
        id: file.id,
        received_by_em_id: file.received_by_em_id,
        received_a_id: this.$store.getters.user.employeeId,
        received_date: this.formatDate(new Date(), 'mm/dd/yyyy')
      }
      this.$http
        .post('hr.holiday/approval', form)
        .then(res => {
          this.$message({
            type: 'success',
            message: snackbarResponse
          })
          this.getRequestList()
        })
    },
    showApprovalStatus (approvers) {
      this.approvers = approvers
      this.dialogTableVisible = true
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    formatDate (date, form = 'LLLL dd') {
      if (date) {
        return format(new Date(date), form)
      } else {
        return ''
      }
    }
  }
}
</script>
